import * as R from 'ramda';

import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Redirect,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';

import { Provider } from 'react-redux';
import { graphql, QueryRenderer } from 'react-relay';
import environment from './Environment';
import Moment from 'moment';

Moment.locale('fr');

const RoutesQuery = graphql`
  query RoutesQuery {
    user {
      id
      username
      firstname
      email
      mustCheckMFA
    }
  } 
`;

import Commands from './Commands';
import Account from './containers/account/Account';
import Layout from './containers/layout/Layout';
import { UserContext } from './withUser';

const addRoute = (path, componentPath, menu, defaultProps = {}) => (routes) => {
  return R.append({
    path,
    menu,
    defaultProps,
    component: require(`./containers/${componentPath}`).default,
  }, routes);
};

let routes = [];

routes = R.pipe(
  addRoute('/contract/find', 'contract/Find', ['Contrats', 'Rechercher']),
  addRoute('/contract/history', 'contract/History', ['Contrats', 'Historique']),
  addRoute('/files/omg/omg', 'files/Omg', ['Contrats', 'Génération OMG', 'OMG Prévoyance et Santé']),
  addRoute('/contract/updatefromomg', 'contract/UpdateFromOMG', ['Contrats', 'Retour des OMG']),
  addRoute('/files/remuneration', 'files/Remuneration', ['Contrats', 'Fichier de rémunération']),
  addRoute('/files/traceoffer', 'files/TraceOffer', ['Contrats', 'Fichier de suivi des offres']),
  addRoute('/files/escFiles', 'files/Esc', ['Contrats', 'Fichier Représentant ESC']),

  addRoute('/ccns/manage', 'ccns/Manage', ['CCNs', 'Gérer']),

  addRoute('/documents/convert', 'documents/Convert', ['Documents', 'Convertir']),

  addRoute('/accounts/find', 'users/Find', ['Comptes', 'Rechercher']),
  addRoute('/users/dump', 'users/Dump', ['Comptes', 'Télécharger']),
  addRoute('/users/updatecommercialmanager', 'users/UpdateCommercialManager', ['Comptes', 'Mettre à jour un manager commercial']),
  addRoute('/users/lock', 'users/Lock', ['Comptes', 'Désactiver']),
  addRoute('/user/commercialCodes', 'users/CommercialCodes', ['Comptes', 'Codes commerciaux']),

  addRoute('/metrics/ccns', 'metrics/Ccns', ['Statistiques', 'CCNs']),
  addRoute('/metrics/contracts', 'metrics/Contracts', ['Statistiques', 'Contrat']),
  addRoute('/metrics/users', 'metrics/Users', ['Statistiques', 'Utilisateurs']),

  addRoute('/universign/details', 'universign/Details', ['Autres', 'Signature électronique']),
  addRoute('/emails/search', 'emails/Search', ['Autres', 'Emails']),
  addRoute('/homemessage', 'homemessage/HomeMessage', ['Autres', 'Configurer un message d\'accueil']),
  addRoute('/tabledeparametrage/prevoyance', 'tabledeparametrage/index', ['Autres', 'Table de paramétrage Prévoyance'], { offerType: 'PREVOYANCE' }),
  addRoute('/tabledeparametrage/sante', 'tabledeparametrage/index', ['Autres', 'Table de paramétrage Santé'], { offerType: 'SANTE' })
)(routes);

const userIsSignedIn = user => R.compose(
  R.not,
  R.isNil,
  R.propOr(null, 'email')
)(user);

const userMustCheckAccount = user => !R.isNil(user) && !R.isNil(user.username) && R.propEq('mustCheckMFA', true, user);

const Routes = ({ store }) => (
  <QueryRenderer
    environment={environment}
    query={RoutesQuery}
    render={({ error, props }) => {
      if (error) {
        return <div>{error.stack}</div>;
      } else if (!props) return null;

      const { user } = props;

      return <UserContext.Provider value={user}>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route path="/account" component={Account}/>
              {!userIsSignedIn(user) && <Redirect to="/account/signin"/>}
              {userMustCheckAccount(user) && <Redirect to="/account/verifyaccount"/>}
              <Layout routes={routes}>
                <Commands routes={routes}/>
              </Layout>
            </Switch>
          </Router>
        </Provider>
      </UserContext.Provider>;
    }}
  />
);

Routes.propTypes = {
  store: PropTypes.object.isRequired
};

export default Routes;
